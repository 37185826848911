jQuery(function() {

	function contactMsg() {
		if( window.location.hash == "#gracias-top") {
			$('.sec-gracias').show();

			jQuery('html, body').stop().animate({
				'scrollTop' : $('#gracias-top').offset().top
			}, 1200, 'swing', function() {
				window.location.hash = '#gracias-top';
			});
		}
	}

	function scrollPage() {

		jQuery('.multirent-menu a').on('click',
			function(e) {
				e.preventDefault();
				var target = this.hash;
				var $target = $(target);

				jQuery('html, body').stop().animate({
					'scrollTop' : $target.offset().top
				}, 1200, 'swing', function() {
					window.location.hash = target;
				});
			});

			jQuery('a[href="#homeContact"]').on('click',
			function(e) {
				e.preventDefault();
				var target = this.hash;
				var $target = $(target);

				jQuery('html, body').stop().animate({
					'scrollTop' : $target.offset().top
				}, 1200, 'swing', function() {
					window.location.hash = target;
				});
			});
	}

	scrollPage();
	contactMsg();

});

$(window).scroll( function() {

	var scroll = $(window).scrollTop();
	//console.log('scroll: ', scroll);
	
	if (scroll >= 100) {
		$('#header').find('.container-fluid').addClass('h-resize');
	} else {
		$('#header').find('.container-fluid').removeClass('h-resize');
	}
});

$('.burger').append('<i></i>')

$(".burger").click(function() {
	$(this).toggleClass('active');
	$("div.menu-content div").slideToggle('fast');
})

$(window).resize(function() {
	if ($(window).width() > 767) {
		$('div.menu-content div').removeAttr('style');
	}
});

wow = new WOW({
	boxClass:     'wow',      // default
	animateClass: 'animate__animated', // default
	offset:       0,          // default
	mobile:       true,       // default
	live:         true        // default
})
wow.init();

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    var panel = this.nextElementSibling;
    if (panel.style.display === "grid") {
      panel.style.display = "none";
    } else {
      panel.style.display = "grid";
    }
  });
}

var items = document.querySelectorAll(".accordion-test button");

function toggleAccordion() {
  var itemToggle = this.getAttribute('aria-expanded');
  
  for (i = 0; i < items.length; i++) {
    items[i].setAttribute('aria-expanded', 'false');
  }
  
  if (itemToggle == 'false') {
    this.setAttribute('aria-expanded', 'true');
  }
}

items.forEach(function(item) { item.addEventListener('click', toggleAccordion) } );
